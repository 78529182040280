<template>
  <div>
    <section>
      <TippCard
        hintID="Admin_Konfiguration_Tab_Funktionen_1_V1"
        text="Funktionen definieren die benötigten Qualifikationen für die Einsatzmittel einer Organisation. Ihr werden Einsatzmitteln zugewiesen, um bei Alarmierung, Termin- und Verfügbarkeitsplanung die Einsatzmittel mit der notwendigen Personalstärke  zu besetzen."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-tag"
                title="Funktionen"
                :actions="actionsPositions"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="positions"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  no-data-text="Keine Funktionen vorhanden"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="position in props.items"
                        :key="position.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <position-detail-card
                          :position="position"
                        ></position-detail-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <custom-dialog
      v-if="helpers.dataImport.dialogModel"
      @close="toggleImportDialog()"
      title="Funktionen importieren"
      text="Importierte Funktionen stehen nach dem Import sofort zur Verfügung."
      width="m"
      :errorMessage="helpers.dataImport.errorMessage"
      :btnAction="startDataImport"
      :btnDisabled="helpers.dataImport.selectedImportTemplates.length === 0"
      :loading="helpers.dataImport.loading"
      btnText="Auswahl importieren"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.dataImport.formIsValid"
          @submit.prevent="startDataImport()"
        >
          <v-card outlined class="mt-2">
            <v-data-table
              v-model="helpers.dataImport.selectedImportTemplates"
              :items="helpers.dataImport.importTemplates"
              :headers="helpers.dataImport.tableHeaders"
              :search="helpers.dataImport.search"
              item-key="title"
              show-select
              :sort-by="helpers.dataImport.sortSelector"
              :sort-desc="false"
              checkbox-color="primary"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              fixed-header
              height="35vh"
              class="mt-1"
              no-results-text="Keine Ergebnisse für Suchbegriff"
            >
              <template v-slot:top>
                <v-card flat>
                  <v-card-subtitle>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="helpers.dataImport.search"
                          label="Nach Funktionen suchen"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <span class="mr-4">Sortieren nach</span>
                        <v-chip-group
                          v-model="helpers.dataImport.sortSelector"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            label
                            value="priority"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            ><v-icon left small>mdi-sort-numeric-variant</v-icon
                            >Priorität
                          </v-chip>
                          <v-chip
                            label
                            value="title"
                            class="transparent-background font-weight-medium mb-3"
                            ><v-icon left small
                              >mdi-sort-alphabetical-variant</v-icon
                            >Alphabetisch</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`item.details`]="{ item }">
                <v-icon left :color="item.color">mdi-circle</v-icon
                ><v-chip small label class="ml-3 mr-4"
                  ><v-icon small left>mdi-dumbbell</v-icon
                  >{{ item.priority }}</v-chip
                >
              </template>
              <template v-slot:[`item.title`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="d-block font-weight-semibold text--primary text-truncate"
                    >{{ item.title }}</span
                  >
                  <small class="grey--text">{{ item.shortTitle }}</small>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import PositionDetailCard from "@/components/availability/units/PositionDetailCard.vue";
import { AVAILABILITY } from "@/store/modules.js";
import { GET_POSITIONS, CREATE_POSITIONS } from "@/store/action-types.js";
import { ADMINISTRATION_CONFIG_POSITIONS_create } from "@/data/permission-types.js";
import { importablePositions } from "@/data/import/importable-positions.js";

export default {
  name: "availability-units-tab-positions-position-new",
  components: {
    TippCard,
    Subheader,
    PositionDetailCard,
    CustomDialog,
  },
  data() {
    return {
      sortDesc: false,
      sortBy: "priority",

      helpers: {
        dataImport: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importablePositions,
          sortSelector: "priority",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          tableHeaders: [
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
            {
              text: "",
              value: "details",
            },
          ],
          snackbar: { model: false, text: "" },
        },
      },

      actionsPositions: [
        {
          title: "Erstellen",
          icon: "mdi-tag-plus",
          permission: `${ADMINISTRATION_CONFIG_POSITIONS_create}`,
          actionStyle: "textBtn",
          function: this.createPosition,
        },
        {
          title: "Importieren",
          icon: "mdi-import",
          permission: `${ADMINISTRATION_CONFIG_POSITIONS_create}`,
          actionStyle: "textBtn",
          function: this.toggleImportDialog,
        },
      ],
    };
  },
  computed: {
    positions() {
      return this.$store.state.availability.positions;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${AVAILABILITY}${GET_POSITIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    createPosition() {
      this.$router.push({
        name: "availability-units-tab-positions-position-new",
      });
    },
    toggleImportDialog() {
      this.helpers.dataImport.dialogModel =
        !this.helpers.dataImport.dialogModel;
    },
    startDataImport() {
      if (this.helpers.dataImport.selectedImportTemplates.length > 0) {
        this.helpers.dataImport.loading = true;
        this.$store
          .dispatch(`${AVAILABILITY}${CREATE_POSITIONS}`, {
            organizationId: this.$route.params.organizationId,

            positions: this.helpers.dataImport.selectedImportTemplates,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleImportDialog();
            this.helpers.dataImport.loading = false;
            this.helpers.dataImport.selectedImportTemplates = [];
            this.helpers.dataImport.search = "";
            this.helpers.dataImport.snackbar.model = true;
            this.helpers.dataImport.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImport.loading = false;
            console.log(error);
            this.helpers.dataImport.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens eine Veranstaltungsart aus, um den Import duchrzuführen."
        );
      }
    },
  },
};
</script>
