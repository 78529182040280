export const importablePositions = [
  {
    title: "Fahrzeugführer",
    shortTitle: "FZF",
    priority: 1,
    substitute: null,
    color: "#673AB7",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.fz",
  },
  {
    title: "Maschinist",
    shortTitle: "MA",
    priority: 2,
    substitute: null,
    color: "#795548",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.ma",
  },
  {
    title: "A-Truppführer",
    shortTitle: "ATF",
    priority: 3,
    substitute: null,
    color: "#F44336",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.atf",
  },
  {
    title: "A-Truppmann",
    shortTitle: "ATL",
    priority: 4,
    substitute: null,
    color: "#F44336",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.atm",
  },
  {
    title: "W-Truppführer",
    shortTitle: "WTF",
    priority: 5,
    substitute: null,
    color: "#2196F3",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.wtf",
  },
  {
    title: "W-Truppmann",
    shortTitle: "WTL",
    priority: 6,
    substitute: null,
    color: "#2196F3",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.wtm",
  },
  {
    title: "Praktikant",
    shortTitle: "PRA",
    priority: 8,
    substitute: null,
    color: "#8BC34A",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.pr",
  },
  {
    title: "Melder",
    shortTitle: "MEL",
    priority: 9,
    substitute: null,
    color: "#607D8B",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.me",
  },
  {
    title: "S-Truppführer",
    shortTitle: "STF",
    priority: 9,
    substitute: null,
    color: "#FFC107",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.stf",
  },
  {
    title: "S-Truppmann",
    shortTitle: "STL",
    priority: 10,
    substitute: null,
    color: "#FFC107",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.stm",
  },

  {
    title: "Führungsassistent",
    shortTitle: "TL",
    priority: 13,
    substitute: null,
    color: "#607D8B",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "fire.fü",
  },

  {
    title: "Medizinisch verantwortliche EK",
    shortTitle: "MVE",
    priority: 14,
    substitute: null,
    color: "#E91E63",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "medic.mv",
  },
  {
    title: "RD-Maschinist",
    shortTitle: "RD-MA",
    priority: 15,
    substitute: null,
    color: "#E91E63",
    preconditions: {
      conjunctionType: "AND",
      skillIds: [],
    },
    isImported: true,
    importVersion: 1,
    importId: "medic.rdm",
  },
];
