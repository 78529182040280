<template>
  <div>
    <NavigationBar
      titel="Funktion erstellen"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <position-editor v-model="position"></position-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { AVAILABILITY } from "@/store/modules.js";
import { CREATE_POSITION } from "@/store/action-types.js";
import PositionEditor from "@/components/availability/units/PositionEditor.vue";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "availability-units-tab-positions",
  components: {
    NavigationBar,
    PositionEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      helpers: {
        formIsValid: false,
      },

      position: {
        title: null,
        shortTitle: null,
        color: "#F44336",
        priority: Number,
        substitute: null,
        preconditions: {
          conjunctionType: "AND",
          skillIds: [],
        },
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {},
    validate() {
      this.$refs.form.validate();
    },
    createItem() {
      this.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${AVAILABILITY}${CREATE_POSITION}`, {
          organizationId: this.$route.params.organizationId,

          title: this.position.title,
          shortTitle: this.position.shortTitle,
          priority: this.position.priority,
          substitute: this.position.substitute,
          color: this.position.color,
          preconditions: {
            conjunctionType: this.position.preconditions.conjunctionType,
            skillIds: this.position.preconditions.skillIds,
          },
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
