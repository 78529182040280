<template>
  <div>
    <NavigationBar
      titel="Funktion bearbeiten"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="updateItem"
      >
        <position-editor v-model="position"></position-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { AVAILABILITY } from "@/store/modules.js";
import { UPDATE_POSITION } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import PositionEditor from "@/components/availability/units/PositionEditor.vue";

export default {
  name: "availability-units-tab-positions-position-edit",
  components: {
    NavigationBar,
    PositionEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      helpers: { formIsValid: false },

      position: null,
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("positions")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.position = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    updateItem() {
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${AVAILABILITY}${UPDATE_POSITION}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,

          title: this.position.title,
          shortTitle: this.position.shortTitle,
          priority: this.position.priority,
          substitute: this.position.substitute,
          color: this.position.color,
          preconditions: {
            conjunctionType: this.position.preconditions.conjunctionType,
            skillIds: this.position.preconditions.skillIds,
          },
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
