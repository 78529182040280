<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="9">
                        <v-text-field
                          v-model="positionLocal.title"
                          label="Bezeichnung"
                          hide-details="auto"
                          outlined
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="positionLocal.shortTitle"
                          label="Kurzbezeichnung"
                          outlined
                          hide-details="auto"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-color-picker
                          v-model="positionLocal.color"
                          :swatches="availableColors"
                          mode="hexa"
                          hide-inputs
                          hide-canvas
                          hide-sliders
                          show-swatches
                          dot-size="2"
                          width="1200"
                          swatches-max-height="150"
                        ></v-color-picker>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-set-merge" title="Verwendung"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model.number="positionLocal.priority"
                          label="Priorität"
                          type="number"
                          hint="Priorisiere Funktionen durch verschiedene Gewichtungen. (Wird nur berücksichtigt, sofern keine andere Funktion die identische Gewichtung besitzt)"
                          persistent-hint
                          prepend-inner-icon="mdi-dumbbell"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-select
                          v-model="positionLocal.substitute"
                          :items="positionsList"
                          item-text="title"
                          hide-details="auto"
                          label="Alternative Funktion"
                          disabled
                          prepend-inner-icon="mdi-repeat"
                          outlined
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader
              icon="mdi-account-multiple-check"
              title="Besetzung"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-radio-group
                          v-model="positionLocal.preconditions.conjunctionType"
                          mandatory
                          label="Besetzungsart"
                        >
                          <v-radio
                            label="Alle Qualifikationen müssen vorhanden sein"
                            value="AND"
                          ></v-radio>
                          <v-radio
                            label="Mind. eine Qualifikation muss vorhanden sein"
                            value="OR"
                          ></v-radio>
                          <!-- <v-radio
                            label="Erweiterte Bedingungen"
                            disabled
                            value="EXTENDED"
                          ></v-radio> -->
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-autocomplete
                          v-model="positionLocal.preconditions.skillIds"
                          :items="skillsList"
                          outlined
                          item-text="title"
                          item-value="meta.id"
                          label="Qualifikationen auswählen"
                          small-chips
                          deletable-chips
                          counter
                          clearable
                          multiple
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_SKILLS } from "@/store/action-types.js";
export default {
  name: "position-editor",
  props: {
    position: {},
  },
  components: {
    Subheader,
  },
  data() {
    return {
      availableColors: [
        ["#F44336"],
        ["#E91E63"],
        ["#9C27B0"],
        ["#673AB7"],
        ["#3F51B5"],
        ["#2196F3"],
        ["#03A9F4"],
        ["#00BCD4"],
        ["#009688"],
        ["#4CAF50"],
        ["#8BC34A"],
        ["#CDDC39"],
        ["#FFEB3B"],
        ["#FFC107"],
        ["#FF9800"],
        ["#FF5722"],
        ["#795548"],
        ["#607D8B"],
        ["#9E9E9E"],
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      priorityItems: [
        { value: 1, text: "Sehr hohe Priorität" },
        { value: 2, text: "Hohe Priorität" },
        { value: 3, text: "Mittlere Priorität" },
        { value: 4, text: "Geringe Priorität" },
        { value: 5, text: "Sehr geringe Priorität" },
      ],
    };
  },
  model: {
    prop: "position",
    event: "positionChange",
  },
  computed: {
    positionLocal: {
      get: function () {
        return this.position;
      },
      set: function (value) {
        this.$emit("positionChange", value);
      },
    },
    skills() {
      return this.$store.state.memberManagement.skills;
    },
    skillsList() {
      const skills = this.skills;
      // Group all skills together by their "group.id"
      const groupItems = (arr) => {
        return arr.reduce((a, c) => {
          if (typeof a[c.group.id] === "undefined") a[c.group.id] = [];
          a[c.group.id] = [...a[c.group.id], c];
          return a;
        }, {});
      };
      const step1 = groupItems(skills);

      // Sort each group by their "sortKey"
      const step2 = Object.keys(step1).map((key) => {
        return step1[key].sort((a, b) => {
          return a.sortKey - b.sortKey;
        });
      });

      // Insert a header with the group.title in front of every group in format {header: "group.title"}; insert a divider after every group in format {divider: true}
      const step3 = step2.map((group) => {
        return [{ header: group[0].group.title }, ...group, { divider: true }];
      });
      return step3.flat();
    },
    positionsList() {
      return [];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_SKILLS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
          return "red";
        case "#E91E63FF":
          return "pink";
        case "#9C27B0FF":
          return "purple";
        case "#673AB7FF":
          return "deep-purple";
        case "#3F51B5FF":
          return "indigo";
        case "#2196F3FF":
          return "blue";
        case "#03A9F4FF":
          return "light-blue";
        case "#00BCD4FF":
          return "cyan";
        case "#009688FF":
          return "teal";
        case "#4CAF50FF":
          return "green";
        case "#8BC34AFF":
          return "light-green";
        case "#CDDC39FF":
          return "lime";
        case "#FFEB3BFF":
          return "yellow";
        case "#FFC107FF":
          return "amber";
        case "#FF9800FF":
          return "orange";
        case "#FF5722FF":
          return "deep-orange";
        case "#795548FF":
          return "brown";
        case "#607D8BFF":
          return "blue-grey";
        case "#9E9E9EFF":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
