<template>
  <div>
    <v-card
      link
      @click="routeDetails(position.meta.id)"
      :style="
        position.color != null
          ? `border-left: 12px solid  ${position.color}`
          : ''
      "
    >
      <v-card-title>
        {{ position.title }} ({{ position.shortTitle }})
      </v-card-title>
      <v-card-text>
        <span>
          <v-icon small>mdi-dumbbell</v-icon>
          {{ position.priority }}
        </span>
        <!-- <span v-if="position.substitute !== undefined || null"> – </span>
        <span v-if="position.substitute">
          <v-icon small>mdi-repeat</v-icon>
          {{ position.substitute.title }} 
        </span> -->
      </v-card-text>
      <v-divider v-if="usedInDetailView"></v-divider>
      <v-card-text
        v-if="usedInDetailView"
        class="subtitle-1 font-weight-medium"
      >
        <div v-if="position.preconditions.conjunctionType === 'AND'">
          Alle folgenden Qualfiikationen müssen zur Besetzung vorhanden sein:
        </div>
        <div v-if="position.preconditions.conjunctionType === 'OR'">
          Mindestens eine der folgenden Qualfiikationen muss zur Besetzung
          vorhanden sein:
        </div>
        <v-row class="match-height mt-1">
          <v-col
            v-for="(skillId, index) in position.preconditions.skillIds"
            :key="index"
            cols="12"
            sm="12"
            md="6"
          >
            <skill-card :skill="getSkill(skillId)" small outlined></skill-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SkillCard from "@/components/member-management/skills/SkillCard.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_SKILLS } from "@/store/action-types.js";
export default {
  name: "position-detail-card",
  props: {
    position: {
      type: Object,
      required: true,
    },
    usedInDetailView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    SkillCard,
  },
  computed: {
    skills() {
      return this.$store.state.memberManagement.skills;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.usedInDetailView) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_SKILLS}`, {
          organizationId: this.$route.params.organizationId,
        });
      }
    },
    createBorderColorStyle(color) {
      const style = "border-left: 12px solid " + color;
      return style;
    },
    routeDetails(item) {
      this.$router.push({
        name: "availability-units-tab-positions-position-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    getSkill(skillId) {
      return this.skills.find((skill) => skill.meta.id === skillId);
    },
  },
};
</script>
