<template>
  <div class="funktion-details">
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-tag-text" title="Informationen"></Subheader>
              <div class="mt-7">
                <position-detail-card
                  :position="data"
                  usedInDetailView
                ></position-detail-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <!-- <v-col cols="12">
              <Subheader
                icon="mdi-repeat"
                title="Funktionen, die diese Funktion als Alternative verwenden"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="alternativeFunktionQuery"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  no-data-text="Keine Funktionen verwenden diese Funktion als Alternative"
                  :items-per-page="5"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="position in props.items"
                        :key="position.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <position-detail-card
                          :position="position"
                        ></position-detail-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col> -->

            <v-col cols="12">
              <Subheader
                icon="mdi-account-box-multiple-outline"
                title="Einsatzmittel mit dieser Funktion"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="einsatzmittelQuery"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  :items-per-page="5"
                  no-data-text="Keine Einsatzmittel mit dieser Funktion vorhanden"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="unit in props.items"
                        :key="unit.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <unit-detail-card :unit="unit" small></unit-detail-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { AVAILABILITY } from "@/store/modules.js";
import { DELETE_POSITION } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import PositionDetailCard from "@/components/availability/units/PositionDetailCard.vue";
import UnitDetailCard from "@/components/availability/units/UnitDetailCard.vue";
import {
  ADMINISTRATION_CONFIG_POSITIONS_update,
  ADMINISTRATION_CONFIG_POSITIONS_delete,
} from "@/data/permission-types.js";

export default {
  name: "admin-config-tab-positions-position-details",
  components: {
    NavigationBar,
    Subheader,
    PositionDetailCard,
    UnitDetailCard,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${ADMINISTRATION_CONFIG_POSITIONS_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permissions: `${ADMINISTRATION_CONFIG_POSITIONS_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      formIsValid: false,

      sortDesc: true,
      sortBy: "bezeichnung",
      einsatzmittelQuery: [],
      alternativeFunktionQuery: [],

      data: null,
      show1: true,
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "position", data: this.data }];
    },
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("positions")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("positions")
        .where("alternative.id", "==", this.$route.params.itemId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              bezeichnung: doc.data().bezeichnung,
              kurzbezeichnung: doc.data().kurzbezeichnung,
              beschreibung: doc.data().beschreibung,
              farbe: doc.data().farbe,
              gewichtung: doc.data().gewichtung,
              alternative: {
                bezeichnung: doc.data().alternative.bezeichnung,
                id: doc.data().alternative.id,
              },
              meta: {
                id: doc.data().meta.id,
              },
            };
            this.alternativeFunktionQuery.push(data);
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("units")
        .where("positionIds", "array-contains", this.$route.params.itemId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.einsatzmittelQuery.push(doc.data());
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    createBorderColorRoundedStyle(farbe) {
      const style = "border: 2px solid " + farbe;
      return style;
    },
    routeDetailsE(item) {
      this.$router.push({
        name: "availability-units-tab-units-unit-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    routeDetailsF(item) {
      this.$router.push({
        name: "availability-units-tab-positions-position-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },

    editItem() {
      this.$router.push({
        name: "availability-units-tab-positions-position-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {
      if (
        !this.einsatzmittelQuery.length > 0 &&
        !this.alternativeFunktionQuery.length > 0
      ) {
        var res = confirm(
          "Bist Du sicher, dass Du diese Funktion löschen möchtest?"
        );
        if (res == true) {
          this.$store.dispatch(`${AVAILABILITY}${DELETE_POSITION}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,
          });
        }
      } else if (!this.einsatzmittelQuery.length == 0) {
        alert(
          "Fehler: Du kannst nur Funktionen löschen, denen keine Einsatzmittel zugeordnet sind."
        );
      } else {
        alert(
          "Fehler: Du kannst nur Funktionen löschen, die nicht als Alternative für andere Funktionen ausgewählt sind."
        );
      }
    },
  },
};
</script>

<style></style>
