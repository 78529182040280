<template>
  <div>
    <NavigationBar
      titel="Einsatzmittel erstellen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <unit-editor v-model="unit"></unit-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { AVAILABILITY } from "@/store/modules.js";
import { CREATE_UNIT } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import UnitEditor from "@/components/availability/units/UnitEditor.vue";

export default {
  name: "availability-units-tab-units-unit-new",
  components: {
    NavigationBar,
    UnitEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],
      helpers: {
        formIsValid: false,
      },

      unit: {
        title: null,
        isExternal: false,
        priority: null,
        forecastingEnabled: false,
        status: "operational",
        crewCombinations: [],
        positionIds: [],
        photoUrl: {},
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {},
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${AVAILABILITY}${CREATE_UNIT}`, {
          organizationId: this.$route.params.organizationId,

          title: this.unit.title,
          priority: this.unit.priority,
          isExternal: this.unit.isExternal,
          forecastingEnabled: this.unit.forecastingEnabled,
          status: this.unit.status,
          photoUrl: this.unit.photoUrl,
          crewCombinations: this.unit.crewCombinations,
          positionIds: this.unit.positionIds,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
