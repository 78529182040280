<template>
  <div>
    <section>
      <TippCard
        hintID="Admin_Konfiguration_Tab_Einsatzmittel_1_V1"
        text="Die Einsatzmittel deiner Organisation setzen sich aus einzelnen Funktionen in gewünschter Personalstärke zusammen. Einsatzmittel können beispielsweise Fahrzeuge, Rufdienste oder Stabsfunktionen abbilden."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-card"
                title="Einsatzmittel"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="units"
                  sort-by="priority"
                  :sort-desc="sortDesc"
                  no-data-text="Keine Einsatzmittel vorhanden"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="unit in props.items"
                        :key="unit.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <unit-detail-card :unit="unit"></unit-detail-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import UnitDetailCard from "@/components/availability/units/UnitDetailCard.vue";
import { AVAILABILITY } from "@/store/modules.js";
import { GET_UNITS } from "@/store/action-types.js";
import { ADMINISTRATION_CONFIG_UNITS_create } from "@/data/permission-types.js";

export default {
  name: "availability-units-tab-units",
  components: {
    TippCard,
    Subheader,
    UnitDetailCard,
  },
  data() {
    return {
      sortDesc: false,
      sortBy: "priority",

      actions: [
        {
          title: "Erstellen",
          icon: "mdi-card-plus",
          permission: `${ADMINISTRATION_CONFIG_UNITS_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "availability-units-tab-units-unit-new",
            });
          },
        },
      ],
    };
  },
  computed: {
    units() {
      return this.$store.state.availability.units;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${AVAILABILITY}${GET_UNITS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
  },
};
</script>
