<template>
  <div class="einsatzmittel-edit">
    <NavigationBar
      titel="Einsatzmittel bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="updateItem"
      >
        <unit-editor v-model="unit"></unit-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { AVAILABILITY } from "@/store/modules.js";
import { UPDATE_UNIT } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import UnitEditor from "@/components/availability/units/UnitEditor.vue";

export default {
  name: "availability-units-tab-units-unit-edit",
  components: {
    NavigationBar,
    UnitEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],
      helpers: {
        formIsValid: false,
      },

      unit: null,
    };
  },
  computed: {
    positionIds() {
      if (this.unit && this.unit.crewCombinations) {
        return this.unit.crewCombinations.reduce((acc, item) => {
          if (item.positions) {
            item.positions.forEach((position) => {
              acc.push(position.position.id);
            });
          }
          return acc;
        }, []);
      } else {
        return [];
      }
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("units")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.unit = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    updateItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${AVAILABILITY}${UPDATE_UNIT}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,

          title: this.unit.title,
          priority: this.unit.priority,
          isExternal: this.unit.isExternal,
          forecastingEnabled: this.unit.forecastingEnabled,
          status: this.unit.status,
          crewCombinations: this.unit.crewCombinations,
          positionIds: this.positionIds,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
